import * as React from "react"
import { Container, Row, Col} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Section } from "../components/Core";
import { Helmet } from "react-helmet"
import Top from '../components/VentureCapital/Top'
import Landing from '../components/VentureCapital/Landing'
import Disclaimer from '../components/VentureCapital/Disclaimer'
import {StaticImage} from 'gatsby-plugin-image';
import Checkout from '../components/VentureCapital/CheckoutUK'
import TestimonialBlock from '../components/VentureCapital/TestimonialBlock'
import '../App.css'

// markup
const IndexPage = props => {
  console.log('withoutdiscount')
  
  return (
    <PageWrapper footerDark>
    <Header />
    <section style={{minHeight: 600, height: '100vh', background: '#274069'}}>
      <Helmet><html lang="en" /><title>Kohdao - Google Ads Done Right !</title>
        <meta name="title" content="Kohdao - Google Ads Done Right !"></meta>
        <meta name="description" content="Get more leads today. Book your free consultation."/>
      </Helmet>
    
      <Top
      home={'/uk/'}
      titleTwo={<>Account Audit + Restructure</>}
      titleOne={<>Google Ads Setup (Part 1) <span role="img" aria-label="investors">🧱</span></>}
      titleMobile={<>Google Ads Setup (Part 1)</>}
      // subTitle={<>2 x 975€</>}
      />
     <section style={{marginTop: -230}}> 
      <Checkout url='https://buy.stripe.com/28oaFIech6CSbRu9AA' text='Google Ads Setup Part 1'/>
      <TestimonialBlock/>
      </section>

      {/* <Section className="position-relative">
		  <Container style={{maxWidth: 1280}}>

      <Row className="align-items-center justify-content-center" >

  <Col lg='4' id='blogCol'>
    <Link to={'/pre-seed-vcs-in-uk-london/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
    <StaticImage src='../../images/pre-seed-vc.jpeg' width={900} height={600}alt='pre seed vc' placeholder='tracedSVG' style={{marginLeft: -1}}/>
    <div style={{padding: 15}}><h3>
    Pre-Seed VCs - The UK Shortlist Is Out. Fund Your Startup Now.
      </h3><p>
      What came first, the seed or the plant? The Pre-seed! 😊 … A pre-seed VC will invest in ranges between 100K-500K. At this level ...
    </p></div></div></Link></Col>

    <Col lg='4' id='blogCol'>
    <Link to={'/seed-vcs-in-uk-london/'} id='textOnHover'><div id='cardOnHover'>
      <StaticImage src='../../images/seed-vcs-uk.png' width={900} height={600}alt='pre seed vc' placeholder='tracedSVG' style={{marginLeft: 0}}/>
      <div style={{padding: 15}}><h3>
      Seed VCs - Don't Miss The Most Active Seed VCs In The UK
        </h3><p>
        Unfortunately, the idea that seed VC money is destined for businesses at the very early “seed” stage of growth is ...
      </p></div></div></Link></Col>


    <Col lg='4' id='blogCol'>
    <Link to={'/startup-accelerators-in-london/'} id='textOnHover'><div style={{border: '1px solid rgba(0,0,0,.125)'}}id='cardOnHover'>
    <StaticImage src='../../images/startup-accelerators.jpeg' width={900} height={600} alt='startup accelerators' placeholder='tracedSVG' style={{marginLeft: -1}}/>
    <div style={{padding: 15}}><h3>
    The Complete Guide To Startup Accelerators In The UK
      </h3><p>
      Startup accelerators are a great way to boost your business launch. They provide structure and accountability that can ...
    </p></div></div></Link> </Col>

    <Col lg='4' id='blogCol'>
    <Link to={'/find-an-investor/investor-questions/'} id='textOnHover'><div id='cardOnHover'>
      <StaticImage src='../../images/investor-questions.png' width={900} height={600} alt='investor questions' placeholder='tracedSVG' style={{marginLeft: -1}}/>
      <div style={{padding: 15}}><h3>
      1st Meeting With An Investor? 126 Questions You Should Prepare.
        </h3><p>
        You’ve just scheduled your first interview with an investor? Well done you! Unfortunately, there’s no time for celebration… this is ...
      </p></div></div></Link>
      </Col>



  </Row>
  </Container>
  </Section> */}

  
  </section>
  {/* <Disclaimer/> */}
  <Footer/>
      </PageWrapper>
   
  )
}

export default IndexPage

import React, { useState } from "react"
import * as BS from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'


const Checkout = (props) => {

  const [loading, setLoading] = useState(false)


  return (
    <BS.Row style={{marginTop: 50, marginBottom: 30}}>
<a href={props.url} target="_blank" style={{maxWidth: 400, margin: 'auto'}}>
    <button class="btn-lg btn btn-primary text-center btn" disabled={loading}
      style={{background: 'rgb(228, 35, 79)', borderColor: 'rgb(228, 35, 79)', paddingLeft: 18, maxWidth: 400, margin: 'auto' }}
      // onClick={redirectToCheckout}
    >
     {props.text}<FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, fontSize: 24, marginLeft: 10}}/>
    </button>
    </a>
    </BS.Row>
  )
}
export default Checkout